"use strict";
const angular = require("angular");

import uiRouter from "angular-ui-router";

import { requestConfigV2 } from "../company.component";
import routes from "./companygenie.routes";
/**
 * @class angular_module.CompanyGenieComponent
 */
export class CompanyGenieComponent {
  /*@ngInject*/
  constructor($http, $scope, $uibModal, Modal, CompanyService, $location) {
    this.$http = $http;
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.CompanyService = CompanyService;
    this.$location = $location;
    this.company_name = "";
    this.isCompanyNameEditMode = false;
    this.company = "";
    this.startDate = new Date();
    this.endDate = new Date();
    this.gamesAvailable = "";
    this.success = false;
    this.error = false;
    this.isActive = null;
  }

  /**
   * @function $onInit
   * @memberOf angular_module.CompFrameworkComponent
   * @fires getCompframeworks
   * @description this function intiates the CompFramework Component.
   */
  $onInit() {
    this.getCompany();
  }

  getCompany() {
    this.$http.get("/api/company/" + sessionStorage.company_id).then(
      (res) => {
        this.company = res.data;
        this.startDate = new Date(this.company.genieSubscription?.startDate);
        this.endDate = new Date(this.company.genieSubscription?.endDate);
        this.gamesAvailable = this.company.genieSubscription?.gamesAvailable;
        this.isActive = this.company.genieSubscription.status === 'active'
      },
      (err) => {
        this.$location.path("/company");
      }
    );
  }

  updateCompany(company) {
    return this.$http.put(`/api/company/${company._id}`, company);
  }

  confirmDate() {
    this.company["genieSubscription"] = {
      usage: this.company.genieSubscription?.usage,
    };

    this.company["genieSubscription"] = {
      ...this.company.genieSubscription,
      startDate: this.startDate,
    };
    this.company["genieSubscription"] = {
      ...this.company.genieSubscription,
      endDate: this.endDate,
    };
    this.company["genieSubscription"] = {
      ...this.company.genieSubscription,
      gamesAvailable: this.gamesAvailable,
    };

    this.company["genieSubscription"] = {
      ...this.company.genieSubscription,
      status: this.isActive ? "active" : "inactive",
    };

    const reqConfig = requestConfigV2("EDIT", {
      _id: this.company._id,
      genieSubscription: this.company.genieSubscription,
    });


    this.updateCompany({ ...this.company })
      .then(() => {
        this.success = true;
        this.getCompany();
          
    this.$http(reqConfig).then((compData) => {
      console.log("Updated in v2", compData);
    });

      })
      .catch(() => {
        this.error = true;
      });

    setTimeout(() => {
      this.success = null;
      this.error = null;
    }, 500);
  }
}

export default angular
  .module("leafApp.companygenie", [uiRouter])
  .config(routes)
  .component("companygenie", {
    template: require("./companygenie.html").default,
    controller: CompanyGenieComponent,
    controllerAs: "companygenieCtrl",
  }).name;
